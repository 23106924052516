import { throwIfMissing } from 'utils';
import { getTranslationItemHash } from 'utils/translation';

import {
	getData,
	postData,
	putData,
} from 'utils/api';

const END_POINT = 'translationItems';
const ACTION_ROOT = 'translationItems';

/* ACTIONS */
const SET_LIST 		= `${ACTION_ROOT}/SET_LIST`;

const initialState = {
	list: [],
	hash: {},
	loaded: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return {
				...state,
				list: action.payload,
				hash: getTranslationItemHash(action.payload),
				loaded: true,
			};
		default:
			return state;
	}
}

export function clearTranslationItemList() {
	return {
		type: SET_LIST,
		payload: [],
	}
}

export const getTranslationItemsByNamePrefix = async (prefix) => {
	return await getData({ endpoint: `${END_POINT}/names/${prefix}` });
}

export const createTranslationItem = async ({
	data=throwIfMissing(),
}) => {
	return await postData({
		data,
		endpoint: END_POINT,
	});
}

export const updateTranslationItem = async ({
	key=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return await putData({
		data,
		endpoint: `${END_POINT}/${key}`,
	});
}
