import { throwIfMissing } from 'utils';
import {
	getData,
	postData,
	putData,
	deleteData,
} from 'utils/api';

const END_POINT = 'admin/suppliers';
const ACTION_ROOT = 'suppliers';

const MSG_SAVE_SUCCESS = `Supplier Saved`;
const MSG_DELETE_SUCCESS = `Supplier Deleted`;

/* ACTIONS */
const SET_LIST                = `${ACTION_ROOT}/SET_LIST`;
const CLEAR_LIST              = `${ACTION_ROOT}/CLEAR_LIST`;
const SET_ACTIVE              = `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE            = `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE   = `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;
const SET_GROWERS             = `${ACTION_ROOT}/SET_GROWERS`;
const CLEAR_GROWERS           = `${ACTION_ROOT}/CLEAR_GROWERS`;
const SET_VENDORS             = `${ACTION_ROOT}/SET_VENDORS`;
const CLEAR_VENDORS           = `${ACTION_ROOT}/CLEAR_VENDORS`;

const initialState = {
	list: [],
	active: {},
	editState: false,
	growers: [],
	vendors: [],
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		case SET_GROWERS:
			return { ...state, growers: action.payload };
		case CLEAR_GROWERS:
			return { ...state, growers: [] };
		case SET_VENDORS:
			return { ...state, vendors: action.payload };
		case CLEAR_VENDORS:
			return { ...state, vendors: [] };
		default:
			return state;
	}
}

export const getAllSuppliers = () => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: END_POINT,
			action: SET_LIST,
		});
	}
}

export function clearAllSuppliers() {
	return {
		type: CLEAR_LIST,
	}
}

export function createSupplier( data, formName ) {
	return async (dispatch) => {
		return await postData({
			dispatch,
			data,
			formName,
			endpoint: END_POINT,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function setActiveSupplierEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveSupplier() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveSupplier( key ) {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
		});
	}
}

export function updateActiveSupplier( key, data, formName ) {
	return async (dispatch) => {
		return await putData({
			dispatch,
			data,
			formName,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function deleteSupplier( key ) {
	return async (dispatch) => {
		return await deleteData({
			endpoint: `${END_POINT}/${key}`,
			successMsg: MSG_DELETE_SUCCESS,
		});
	}
}

export const getSupplierGrowers = (key) => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `organizations/${key}/supplier/growers`,
			action: SET_GROWERS,
		});
	}
}

export function clearSupplierGrowers() {
	return {
		type: CLEAR_GROWERS,
	}
}

export const removeSupplierGrower = ({
	programKey=throwIfMissing(),
	growerKey=throwIfMissing(),
	vendorKey=throwIfMissing(),
	supplierKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const response = await deleteData({
			endpoint: `admin/growers/${growerKey}/program/${programKey}/vendor/${vendorKey}`,
			successMsg: "Grower removed from Supplier",
		});

		if ( response.error ) {
			return response;
		}

		await getSupplierGrowers(supplierKey)(dispatch);
		return response;
	}
}

export const getSupplierVendors = (key) => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `organizations/${key}/supplier/vendors`,
			action: SET_VENDORS,
		});
	}
}

export function clearSupplierVendors() {
	return {
		type: CLEAR_VENDORS,
	}
}
