import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToMarkdown } from "draft-js-export-markdown";
import {stateFromMarkdown} from 'draft-js-import-markdown';

import { showError } from 'utils';
import { toolbarOptions } from './constants';
import Feedback from '../Feedback';

class Control extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    editorState: EditorState.createEmpty(),
			markdown: null,
	  }

		this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
	}

	componentDidMount() {
		this.compareStateToProps({ input: {value: null} });
	}

	componentDidUpdate(prevProps) {
		this.compareStateToProps(prevProps);
	}

	compareStateToProps(prevProps) {
		const {
			state: {
				markdown,
			},
			props: {
				input: {
					value,
				},
			},
		} = this;

		const {
			input: {
				value: prevValue,
			},
		} = prevProps;

		if (value === markdown) {
			return;
		}

		if ( value === prevValue ) {
			return;
		}

		let contentState = stateFromMarkdown(value);

		this.setState({
			editorState: EditorState.createWithContent(contentState)
		})
	}

	get wrapperClassName() {
		const {
			meta,
		} = this.props;

		let classNames = [
			"form-control",
			"form-control-wysiwyg",
		];

		if (showError(meta)) {
			classNames.push("is-invalid");
		}

		return classNames.join(" ");
	}

	handleEditorStateChange(editorState) {
		const markdown = stateToMarkdown(editorState.getCurrentContent());

		const {
			input,
		} = this.props;

		this.setState({
			editorState,
			markdown,
		}, () => {
			input.onChange(markdown);
			input.onBlur();
		});
	}

	render() {
		const {
			handleEditorStateChange,
			wrapperClassName,
			props: {
				meta,
			},
			state: {
				editorState,
			},
		} = this;

		return (
			<React.Fragment>
				<div className={ wrapperClassName } style={{height: "auto"}}>
					<Editor
						editorState={ editorState }
						onEditorStateChange={ handleEditorStateChange }
						toolbar={ toolbarOptions }
					/>
				</div>

				<Feedback meta={ meta } />
			</React.Fragment>
		)
	}
}

Control.propTypes = {
	/** props (usually from ReduxForm.Field) with required onChange function to synch input and typeahead */
	input: PropTypes.shape({
		onChange: PropTypes.func.isRequired,
		onBlur: PropTypes.func.isRequired,
	}).isRequired,

	/** meta (usually from ReduxForm.Field) to determine error status and message **/
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}).isRequired,
};


export default Control;
