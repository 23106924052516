import { throwIfMissing } from 'utils';
import {
	getData,
	postData,
} from 'utils/api';

const endpoint = (orgKey) => {
	return `organizations/${orgKey}/surveyResponses`
};
const ACTION_ROOT = 'surveyResponses';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const CLEAR_LIST							= `${ACTION_ROOT}/CLEAR_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;

const initialState = {
	list: [],
	active: {},
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		default:
			return state;
	}
}

export const getSurveyResponse = ({
	organizationKey=throwIfMissing(),
	surveyResponseKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			endpoint: `${endpoint(organizationKey)}/${surveyResponseKey}`,
			dispatch,
			action: SET_ACTIVE,
		});
	}
}

export const createOrgSurveyResponse = ({
	organizationKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const response = await postData({
			data,
			endpoint: endpoint(organizationKey),
			action: SET_ACTIVE,
		});

		return response;
	}
}

export function clearSurveyResponse() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getAllSurveyResponses = ({
	organizationKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			endpoint: endpoint(organizationKey),
			dispatch,
			action: SET_LIST,
		});
	}
}

export function clearSurveyResponseList() {
	return {
		type: CLEAR_LIST,
	}
}
