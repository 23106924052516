import { getCurrentBaseUrl } from 'utils';
import { getPathAdminAccept } from 'Routes/paths';
import * as labels from 'data/labels';

import {
	getData,
	putData,
	deleteData,
	showSuccessMessage,
} from 'utils/api';

const ACTION_ROOT = 'users';
const MSG_SAVE_SUCCESS = 'User Saved';

const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_LIST 						= `${ACTION_ROOT}/CLEAR_LIST`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

const apiRequestUsers = async ( endpoint, dispatch ) => {
	return await getData({
		dispatch,
		endpoint,
		action: SET_LIST,
	});
}

const apiRequestUser = async ( userKey, dispatch ) => {
	return await getData({
		dispatch,
		endpoint: `admin/users/${userKey}`,
		action: SET_ACTIVE,
	});
}

const apiRequestFilteredUsers = async ( filters={}, dispatch ) => {
	if( filters.orgKey ) {
		const endpoint = `organizations/${filters.orgKey}/users`;		// TODO: request changing endpoint to be "organization" to match other endpoints
		return await apiRequestUsers(endpoint, dispatch);
	}
	else if( filters.role ) {
		const endpoint = `roles/${filters.role}/users`;
		return await apiRequestUsers(endpoint, dispatch);
	}
	else {
		const endpoint = `admin/users/all`;
		return await apiRequestUsers(endpoint, dispatch);
	}
}

export function getAllUsers() {
	return async (dispatch) => {
		const endpoint = `admin/users/all`;
		return await apiRequestUsers(endpoint, dispatch);
	}
}

export function getUsersInOrg(orgKey) {
	return async (dispatch) => {
		return await apiRequestFilteredUsers({ orgKey }, dispatch);
	}
}

export function getUsersByRole( role ) {
	return async (dispatch) => {
		const endpoint = `roles/${role}/users`;
		return await apiRequestUsers(endpoint, dispatch);
	}
}

export function getFilteredUsers( filters ) {
	return async (dispatch) => {
		return await apiRequestFilteredUsers( filters, dispatch );
	}
}

export function setActiveUserEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearUserList() {
	return function( dispatch ) {
		dispatch({
				type: CLEAR_LIST,
		});
	}
}

export function clearActiveUser() {
	return function( dispatch ) {
		dispatch({
				type: CLEAR_ACTIVE,
		});
	}
}

export function getActiveUser( userKey ) {
	return async (dispatch) => {
		return await apiRequestUser( userKey, dispatch );
	}
}

export function updateActiveUser( key, data, formName, listFilters ) {
	return async (dispatch) => {

		const finalData = {
			...data,
			emailAddresses: [data.correspondenceEmailAddress],
		};

		const response = await putData({
			dispatch,
			data: finalData,
			formName,
			endpoint: `users/${key}`,
			successMsg: MSG_SAVE_SUCCESS,
		});

		await (dispatch)(getActiveUser(key));
		await apiRequestFilteredUsers(listFilters, dispatch);

		return response;
	}
}

export function inviteAdminUser( formData, formName, listFilters ) {
	return async (dispatch) => {
		const data = {
			...formData,
			url: `${getCurrentBaseUrl()}${getPathAdminAccept()}`
		};

		const response = await putData({
			dispatch,
			data,
			formName,
			endpoint: 'admin/users/promote',
		});

		await apiRequestFilteredUsers(listFilters, dispatch);
		showSuccessMessage(`${data.emailAddress} has been invited to become an admin.`);

		return response;
	}
}

export function removeAdminRole( userKey, listFilters ) {
	return async (dispatch) => {
		const response =  await deleteData({
			dispatch,
			endpoint: `admin/users/demote/${userKey}`,
		});

		await apiRequestUser( userKey, dispatch );
		await apiRequestFilteredUsers(listFilters, dispatch);
		showSuccessMessage(`The user is no longer an admin.`);

		return response;
	}
}

export function removeUserFromOrg(organizationKey, userKey, listFilters) {
	return async (dispatch) => {
		// const endpoint = getOrganizationEndpoint( organizationKey, 'users' );
		const endpoint = `organizations/${organizationKey}/users/${userKey}`;		// TODO: request changing endpoint to be "organization" to match other endpoints

		const response =  await deleteData({
			endpoint,
			dispatch,
		});

		await apiRequestFilteredUsers(listFilters, dispatch);
		showSuccessMessage(`The user has been removed from this ${labels.Organization.toLowerCase()}.`);

		return response;
	}
}

export function inviteUserToOrg( programKey, organizationKey, formData, formName, listFilters ) {
	return async (dispatch) => {
		const data = {
			...formData,
			url: (formData.url) ? formData.url : `${getCurrentBaseUrl()}/admin-accept`,
		};

		const endpoint = `organizations/${organizationKey}/users/invite?programKey=${programKey}`;

		const response = await putData({
			dispatch,
			data,
			endpoint,
			formName,
			successMsg: `${data.emailAddress} has been invited.`
		});

		await apiRequestFilteredUsers(listFilters, dispatch);

		return response;
	}
}
