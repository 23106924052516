import React from 'react';
import ParamsInit from './ParamsInit';
import {
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

import {
	BypassIfAuth,
	RequireAuth,
	RequireAdmin,
	RequireOrg,
} from './PageAuth';


export const PageInit = (props) => {
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();

	return (
		<ParamsInit
			{...props}
			match={{
				url: location.pathname,
				params,
			}}
			location={ location }
			navigate={ navigate }
		/>
	)
}

export const BypassIfAuthInit = (props) => {
	return (
		<BypassIfAuth>
			<PageInit {...props} />
		</BypassIfAuth>
	);
}

export const AuthPageInit = (props) => {
	return (
		<RequireAuth>
			<PageInit {...props} />
		</RequireAuth>
	);
}

export const AdminPageInit = (props) => {
	return (
		<RequireAdmin>
			<PageInit {...props} />
		</RequireAdmin>
	);
}

export const OrgPageInit = (props) => {
	return (
		<RequireOrg>
			<PageInit {...props} />
		</RequireOrg>
	);
}
