import { throwIfMissing } from 'utils';

import {
	getAndMergeList,
	getAndMergeSingle,
	updateOrCreateTranslationItems,
} from 'utils/translation';

import {
	postData,
	putData,
	deleteData,
} from 'utils/api';

const endpoint = (standardKey) => {
	return `standards/${standardKey}/items`
};

const ACTION_ROOT = 'standard-items';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllStandardItems = ({
	standardKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const keys = {
			standardKey,
		};

		return await getAndMergeList({
			keys,
			endpoint: endpoint(keys.standardKey),
			listKeyType: "standardItemKey",
			dispatch,
			action: SET_LIST,
		});

	}
}

export function clearStandardItemList() {
	return {
		type: SET_LIST,
		payload: [],
	}
}

export const createStandardItem = ({
	standardKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const response = await postData({
			data: restData,
			endpoint: endpoint(standardKey),
		});

		if ( response.error ) {
			return response;
		}

		const standardItemKey = response.data.key;

		const keys = {
			standardKey,
			standardItemKey,
		};

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveStandardItem({ standardKey, standardItemKey })(dispatch);

		return response;
	}
}

export function setActiveStandardItemEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveStandardItem() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getActiveStandardItem = ({
	standardKey=throwIfMissing(),
	standardItemKey=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			standardKey,
			standardItemKey,
		};

		return await getAndMergeSingle({
			keys,
			dispatch,
			endpoint: `${endpoint(keys.standardKey)}/${keys.standardItemKey}`,
			action: SET_ACTIVE,
		});

	}
}

export const updateStandardItem = ({
	standardKey=throwIfMissing(),
	standardItemKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const keys = {
			standardKey,
			standardItemKey,
		};

		const response = await putData({
			data: restData,
			endpoint: `${endpoint(standardKey)}/${standardItemKey}`,
		});

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveStandardItem({ standardKey, standardItemKey })(dispatch);

		return response;
	}
}

export const deleteStandardItem = ({
	standardKey=throwIfMissing(),
	standardItemKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: `${endpoint(standardKey)}/${standardItemKey}`,
			action: CLEAR_ACTIVE,
		});
	}
}
