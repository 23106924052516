import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

export class Label extends Component {
	get horizontalProps() {
		const {
			horizontal,
		} = this.props;

		if ( ! horizontal ) {
			return {};
		}

		return {
			column: true,
		}
	}
	render() {
		const {
			horizontalProps,
			props: {
				label,
				horizontal,       // removing horizontal from restLabelProps
				...restLabelProps
			},
		} = this;

		return (
			<Form.Label {...horizontalProps} {...restLabelProps}>
				{ label }
			</Form.Label>
		)
	}
}

Label.propTypes = {
	...Form.Label.propTypes,

	/** render horizontal layout */
	horizontal: PropTypes.bool,

	/** label text */
	label: PropTypes.node,
}

Label.defaultProps = {
	sm: 3,
	className: "text-sm-right",
}

export default Label;
