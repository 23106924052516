import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormGroupLayout from './FormGroupLayout';
import Control from './Control';
import ReadView from "./ReadView";

export { FormGroupLayout };
export { Control };
export { ReadView };

/** returns ReduxForm.Field with defaults */
export const FormControlInput = (props) => {
	return (
		<Field { ...props }/>
	);
}

FormControlInput.propTypes = {
	/** Pass through model to value (ReduxForm.Field) */
	name: PropTypes.string.isRequired,  // Pass through model to value (field in redux-form 5)

	/** component to render, defaults to FormGroupLayout */
	component: PropTypes.func,
};

FormControlInput.defaultProps = {
	component: FormGroupLayout,
};

export default FormControlInput;
