import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from 'store/modules/auth';
import { isAdmin } from 'utils/user';
import { getActiveOrgs } from 'utils/org';
import {
	getProgramV1Path,
	getPathProgramV1Questions,
} from 'Routes/paths';

	class ParamsInit extends Component {
		state = {
			loaded: false,
		}

		get programLoaded() {
			const {
				currentProgram,
				match: {
					params: {
						programKey,
					},
				},
			} = this.props;
			
			if ( !programKey ) {
				return true;
			}

			return (programKey === currentProgram.key);
		}

		componentDidMount(){
			this.setParams();
		}

		componentDidUpdate(prevProps) {
			const {
				orgKey: prevOrgKey,
				programKey: prevProgramKey,
			} = prevProps.match.params;

			const {
				orgKey,
				programKey,
			} = this.props.match.params;

			if (
				orgKey !== prevOrgKey
				|| programKey !== prevProgramKey
			) {
				this.setParams();
			}
		}

		setParams = async () => {
			const { currentUser } = this.props;

			// IF CURRENTUSER ISN'T LOADED, IT'S BECAUSE USER ISN'T AUTHENTICATED - DON'T INIT DATA
			if( ! currentUser || ! currentUser.key ) {
				this.setState({ loaded: true });
				return;
			}

			await this.checkOrgKey();
			await this.checkProgramKey();

			this.setState({ loaded: true });
		}

		checkOrgKey = async () => {
			const {
				currentOrg,
				currentUser,
				setCurrentOrg,
				setCurrentOrgWithOrg,
				clearCurrentOrg,
				match: {
					params: {
						orgKey,
					},
				},
			} = this.props;

			// INITIALIZE CURRENT ORG FROM ORG KEY
			if( orgKey ) {
				if (  ! currentOrg || currentOrg.key !== orgKey ) {
					if ( currentUser && currentUser.key ) {

						if( isAdmin( currentUser.roles ) ) {
							await setCurrentOrg( orgKey );
							return;
						}

						const attachedOrg = currentUser.organizations.find( (org) => org.key === orgKey );
						if( attachedOrg ) {
							setCurrentOrgWithOrg( attachedOrg );
							return;
						}
					}

					const org = {
						key: orgKey,
						name: '',
					}
					setCurrentOrgWithOrg( org );

				}
			}
			else {
				const activeOrgs = getActiveOrgs(currentUser.organizations);
				if( ! isAdmin( currentUser.roles ) && activeOrgs.length === 1 ) {
					if (  ! currentOrg || currentOrg.key !== activeOrgs[0].key ) {
						setCurrentOrgWithOrg( activeOrgs[0] );
					}
				}
				else if( currentOrg.key ) {
					clearCurrentOrg();
				}
			}
		}

		checkProgramKey = async () => {
			const {
				currentProgram,
				clearActiveProgram,
				setActiveProgram,
				navigate,
				match: {
					url,
					params: {
						programKey,
					},
				},
			} = this.props;

			if ( ! programKey ) {
				clearActiveProgram();
				return;
			}

			if ( currentProgram.key === programKey ) {
				return;
			}

			const { data } = await setActiveProgram(programKey);
			if (
				data.version === 1
				&& url.indexOf(getProgramV1Path("", programKey)) < 0
			) {
				navigate(getPathProgramV1Questions(programKey));
			}
		}

		render() {
			const {
				programLoaded,
				state: {
					loaded,
				},
				props: {
					ComposedComponent,
					...restProps
				},
			} = this;

			if ( !loaded || !programLoaded ) {
				return null;
			}

			return <ComposedComponent {...restProps} />;
		}
	}

	function mapStateToProps(state) {
		return {
			currentOrg: state.auth.currentOrg,
			currentProgram: state.auth.currentProgram,
			availableGivingEvents: state.auth.availableGivingEvents,
			currentGivingEventOrg: state.auth.currentGivingEventOrg,
			currentUser: state.auth.currentUser,
		}
	}

	ParamsInit.propTypes = {
		match: PropTypes.shape({
			url: PropTypes.string,
			params: PropTypes.shape({
				orgKey: PropTypes.string,
				programKey: PropTypes.string,
			}).isRequired,
		}).isRequired,
		navigate: PropTypes.func.isRequired,
		ComposedComponent: PropTypes.object.isRequired,
	};

	ParamsInit = connect(mapStateToProps, actions)(ParamsInit);
	export default ParamsInit;
