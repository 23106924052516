import * as paths from 'Routes/paths';
import { DEFAULT_STANDARD } from 'data/constants';
import { throwIfMissing } from 'utils';

export const adminMenu = [
    // {
    //     name: 'Dashboard',
    //     path: paths.getPathAdminDashboard(),
    //     icon : 'fas fa-fw fa-th',
    // },
		{
        name: 'Programs',
        path: paths.getPathPrograms(),
        icon : 'fas fa-fw fa-poll-h',
    },
		{
        name: 'Documents',
        path: paths.getPathDocumentTypes(),
        icon : 'fas fa-fw fa-file-alt',
    },
		{
        name: 'EFI Standards',
        path: paths.getPathStandardItems(DEFAULT_STANDARD),
        icon : 'fas fa-fw fa-book-open',
    },
		{
			name: 'System Users',
			icon: 'fas fa-w fa-users',
			submenu: [
				{
					name: 'System Admins',
					path: paths.getPathSystemAdmins(),
				},
				{
					name: "Growers",
					path: paths.getPathGrowers(),
				},
				{
					name: "Suppliers",
					path: paths.getPathSuppliers(),
				},
				{
					name: "Buyers",
					path: paths.getPathBuyers(),
				},
			]
		},
    // {
    //     name: 'Menu',
    //     icon: 'icon-speedometer',
    //     translate: 'sidebar.nav.MENU',
    //     label: { value: 1, color: 'info' },
    //     submenu: [{
    //         name: 'Submenu',
    //         translate: 'sidebar.nav.SUBMENU',
    //         path: '/submenu'
    //     }]
    // },
];

export const programMenu = ({
	programKey=throwIfMissing(),
	supplierSurveyKey,
	growerSurveyKey,
}) => {
	const assessmentMenu = (label, surveyKey) => {
		if (!surveyKey) {
			return [];
		}

		return [
			{
				name: label,
				icon : 'fas fa-fw fa-clipboard-list',
				submenu: [
					{
						name: 'Questions',
						path: paths.getPathProgramSurveyQuestions(programKey, surveyKey),
					},
					{
						name: 'Sections',
						path: paths.getPathProgramSurveySections(programKey, surveyKey),
					},
					{
						name: 'Answers',
						path: paths.getPathProgramSurveyAnswers(programKey, surveyKey),
					},
					{
						name: 'Tags',
						path: paths.getPathProgramSurveyTags(programKey, surveyKey),
					},
				],
			},
	
		]
	}
	return [
		{
			name: 'Suppliers',
			path: paths.getPathProgramSuppliers(programKey),
			icon : 'fas fa-fw fa-truck',
		},
		{
			name: 'Growers',
			path: paths.getPathProgramGrowers(programKey),
			icon : 'fas fa-fw fa-seedling',
		},
		{
			name: 'Reports',
			path: paths.getPathProgramReports(programKey),
			icon : 'fas fa-fw fa-table-list',
		},
		...assessmentMenu("Supplier Assessment", supplierSurveyKey),
		...assessmentMenu("Grower Assessment", growerSurveyKey),
		{
			name: 'Config',
			icon : 'fas fa-fw fa-cog',
			submenu: [
				{
					name: 'General',
					path: paths.getPathProgramConfig(programKey),
				},
				{
					name: "LAB Content",
					path: paths.getPathProgramClientContent(programKey),
				},
			],
		},
	];

}

export const programV1Menu = ({
	programKey=throwIfMissing(),
}) => {
	return [
		{
			name: 'Growers',
			path: paths.getPathProgramV1Growers(programKey),
			icon : 'fas fa-fw fa-seedling',
		},
		{
			name: 'Questions',
			path: paths.getPathProgramV1Questions(programKey),
			icon : 'fas fa-fw fa-question-circle',
		},
		{
			name: 'Sections',
			path: paths.getPathProgramV1Sections(programKey),
			icon : 'fas fa-fw fa-list',
		},
		{
			name: 'Config',
			path: paths.getPathProgramV1Config(programKey),
			icon : 'fas fa-fw fa-cog',
		},
	];

}

export const orgMenu = [
	{
		name: 'Dashboard',
		orgPath: paths.getPathOrgDashboard,
		icon : 'fas fa-fw fa-th',
	},
	{
		name: 'Users',
		orgPath: paths.getPathOrgUsers,
		icon : 'fas fa-fw fa-users',
	},
]
