import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

export const HelpText = (props) => {
	const {
		message,
	} = props;

	if ( ! message ) {
		return null;
	}

	return (
		<Form.Control.Feedback type="info">
			<small><em>{ message }</em></small>
		</Form.Control.Feedback>
	);
}

HelpText.propTypes = {
	/** message to display **/
	message: PropTypes.string,
}

export default HelpText
