import clientContentData from 'data/clientContent';

import { throwIfMissing } from 'utils';
import {
	buildName,
	getTranslationItemHash,
	updateOrCreateTranslationItems,
} from 'utils/translation';

import { getTranslationItemsByNamePrefix } from 'store/modules/admin/translation-items';

const ACTION_ROOT = 'client-pages';

/* ACTIONS */
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	// TODO: ADD list PROPERTY
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export function setActiveClientContentEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveClientContent() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getActiveClientContent = ({
	programKey=throwIfMissing(),
	clientContentKey=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			programKey,
			clientContentKey,
		};

		const page = clientContentData.find( ({ key }) => key === clientContentKey );

		const translationItemsPrefix = buildName(keys);
		const { data: translationItemsList } = await getTranslationItemsByNamePrefix(translationItemsPrefix);

		const translationItems = getTranslationItemHash(translationItemsList, translationItemsPrefix);

		const payload = {
			...page,
			translationItems
		};

		dispatch({
			payload,
			type: SET_ACTIVE,
		});

		return payload;
	}
}

export const updateClientContent = ({
	programKey=throwIfMissing(),
	clientContentKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
		} = data;

		const keys = {
			programKey,
			clientContentKey,
		};

		dispatch(setActiveClientContentEditState(false));

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveClientContent({ programKey, clientContentKey })(dispatch);
	}
}
