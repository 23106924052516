import {
	getData,
	putData,
} from 'utils/api';

const END_POINT = 'surveys';
const ACTION_ROOT = 'surveys';

const MSG_SAVE_SUCCESS = `Survey Saved`;

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const CLEAR_LIST 						= `${ACTION_ROOT}/CLEAR_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllSurveys = () => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: END_POINT,
			action: SET_LIST,
		});
	}
}

export function clearSurveyList() {
	return {
		type: CLEAR_LIST,
	}
}

export function getActiveSurvey(key) {
	return async ( dispatch ) => {
		return await getData({
			dispatch,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
		});
	}
}

export function setActiveSurveyEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveSurvey() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function updateSurvey( key, data, formName ) {
	return async (dispatch) => {
		return await putData({
			dispatch,
			data,
			formName,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}
