import FormControlInput from 'components/Lib/BootstrapReduxForm/FormControlInput';
import FormControlMarkdownWysiwyg from 'components/Lib/BootstrapReduxForm/FormControlMarkdownWysiwyg';

export const clientPages = [
	{
		key: "pageAdminAccept",
		name: "User Sign Up Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "informationGrowerMarkdown",
				label: "Info (Grower)",
				FormControl: FormControlMarkdownWysiwyg,
			},
			{
				key: "informationSupplierMarkdown",
				label: "Info (Supplier)",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageCertification",
		name: "Grower - Certification Selection Screen",
		fields: [
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageDirectWorkerAssessment",
		name: "Grower - Worker Assessment Selection Screen",
		fields: [
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageEngagementDashboard",
		name: "Grower - Engagement Dashboard Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageGrowerAccept",
		name: "Grower - Supplier Invite Landing Page",
		fields: [
			{
				key: "title",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "newOrgInfoMarkdown",
				label: "Info (New Organization)",
				FormControl: FormControlMarkdownWysiwyg,
			},
			{
				key: "existingOrgInfoMarkdown",
				label: "Info (Existing Organization)",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageLogin",
		name: "Login Screen",
		fields: [
			{
				key: "information",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pagePasswordRequest",
		name: "Password Request Screen",
		fields: [
			{
				key: "information",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageSubscribe",
		name: "Subscribe / Payment Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageGrowerAbout",
		name: "Grower - About Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageSupplierAbout",
		name: "Supplier - About Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
	{
		key: "pageVendorInvite",
		name: "Supplier - Send Grower Invite Screen",
		fields: [
			{
				key: "header",
				label: "Title",
				FormControl: FormControlInput,
			},
			{
				key: "infoMarkdown",
				label: "Info",
				FormControl: FormControlMarkdownWysiwyg,
			},
		],
	},
]

export const clientCommon = [
	{
		key: "commonGeneral",
		name: "General",
		fields: [
			{
				key: "headerTitle",
				label: "Header Title",
				FormControl: FormControlInput,
			},
			{
				key: "appTitle",
				label: "Browser Tab Title",
				FormControl: FormControlInput,
			},
		],
	},
]

const clientContent = [
	...clientPages,
	...clientCommon,
];

export default clientContent;
