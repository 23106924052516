import { throwIfMissing } from 'utils';
import { getData } from 'utils/api';

import {
	getTranslationItemsByNamePrefix,
	createTranslationItem,
	updateTranslationItem,
} from 'store/modules/admin/translation-items';

const prefixDocumentType = "documentType";
const prefixStandard = "standard";
const prefixStandardItem = "standardItem";
const prefixProgram = "program";
const prefixSurvey = "survey";
const prefixSection = "section";
const prefixQuestion = "question";
const prefixQuestionFeedback = "questionFeedback";
const prefixResourceLink = "resourceLink";
const prefixAnswer = "answer";
const prefixTag = "tag";
const prefixClientContent = "clientContent";

export const buildName = ({
		documentTypeKey,
		standardKey,
		standardItemKey,
		programKey,
		surveyKey,
		sectionKey,
		questionKey,
		questionFeedbackKey,
		resourceLinkKey,
		answerKey,
		tagKey,
		clientContentKey,
		fieldName,
}) => {
	let nameParts = [];

	// DOCUMENT TYPES
	if ( documentTypeKey || documentTypeKey === "" ) {
		nameParts.push(prefixDocumentType);
		nameParts.push(documentTypeKey);
	}

	// STANDARDS
	if ( standardKey || standardKey === "" ) {
		nameParts.push(prefixStandard);
		nameParts.push(standardKey);
	}

	if ( standardItemKey ) {
		nameParts.push(prefixStandardItem);
		nameParts.push(standardItemKey);
	}

	// PROGRAMS
	if ( programKey || programKey === "" ) {
		nameParts.push(prefixProgram);
		nameParts.push(programKey);
	}

	if ( clientContentKey ) {
		nameParts.push(prefixClientContent);
		nameParts.push(clientContentKey);
	}

	// SURVEYS
	if ( surveyKey || surveyKey === "" ) {
		nameParts.push(prefixSurvey);
		nameParts.push(surveyKey);
	}

	if ( sectionKey ) {
		nameParts.push(prefixSection);
		nameParts.push(sectionKey);
	}

	if ( questionKey ) {
		nameParts.push(prefixQuestion);
		nameParts.push(questionKey);
	}

	if ( questionFeedbackKey ) {
		nameParts.push(prefixQuestionFeedback);
		nameParts.push(questionFeedbackKey);
	}

	if ( resourceLinkKey ) {
		nameParts.push(prefixResourceLink);
		nameParts.push(resourceLinkKey);
	}

	if ( answerKey ) {
		nameParts.push(prefixAnswer);
		nameParts.push(answerKey);
	}

	if ( tagKey ) {
		nameParts.push(prefixTag);
		nameParts.push(tagKey);
	}

	if (fieldName) {
		nameParts.push(fieldName);
	}

	return nameParts.join("-");
}

export const getTranslationItemHash = (list, removePrefix) => {
	const hash = {};

	const alteredList = list.map( (item) => {
		if ( ! removePrefix ) {
			return item;
		}

		return {
			...item,
			name: item.name.replace(`${removePrefix}-`, ""),
		}
	})

	alteredList.forEach( (item) => {
		const {
			name,
			locale,
		} = item;

		if ( ! hash[name] ) {
			hash[name] = {};
		}

		hash[name][locale] = item;
	});

	return hash;
}

export const getTranslationItemList = (hash, addPrefix) => {
	let list = [];

	const hashKeys = Object.keys(hash);
	hashKeys.forEach( (fieldName) => {
		const field = hash[fieldName];

		const localeKeys = Object.keys(field);
		localeKeys.forEach( (locale) => {
			const data = field[locale];

			const name = (addPrefix) ? `${addPrefix}-${fieldName}` : fieldName;

			list.push({
				...data,
				name,
				locale,
			})
		})
	})
	return list;
}

export const getAndMergeList = async ({
	endpoint=throwIfMissing(),
	keys=throwIfMissing(),
	listKeyType=throwIfMissing(),
	dispatch,
	action,
}) => {
	const response = await Promise.all([
		getData({ endpoint }),
		getTranslationItemsByNamePrefix(buildName(keys)),
	]);

	const list = response[0].data;
	const allTranslationItems = response[1].data;

	if ( ! list ) {
		return [];
	}

	const mergedList = list.map( (data) => {
		const currentKeys = {
			...keys,
			[listKeyType]: data.key,
		};

		const filteredItems = allTranslationItems.filter( (item) => {
			const {
				name,
			} = item;

			return ( name.indexOf(buildName(currentKeys)) === 0 );
		});

		const translationItems = getTranslationItemHash(filteredItems, buildName(currentKeys));

		return {
			...data,
			translationItems,
		}
	});

	if ( dispatch && action ) {
		dispatch({
			type: action,
			payload: mergedList,
		});
	}

	return mergedList;
}

export const getAndMergeSingle = async ({
	endpoint=throwIfMissing(),
	keys=throwIfMissing(),
	dispatch,
	action,
}) => {
	const translationItemsPrefix = buildName(keys);

	const response = await Promise.all([
		getData({ endpoint }),
		getTranslationItemsByNamePrefix(translationItemsPrefix),
	]);

	const data = response[0].data;
	const translationItemsList = response[1].data;

	const translationItems = getTranslationItemHash(translationItemsList, translationItemsPrefix);

	const merged = {
		...data,
		translationItems
	};

	if ( dispatch && action ) {
		dispatch({
			type: action,
			payload: merged,
		});
	}

	return merged;
}

export const updateOrCreateTranslationItems = async({
	translationItems=throwIfMissing(),
	keys=throwIfMissing(),
}) => {
	const translationList = getTranslationItemList(translationItems, buildName(keys));

	await Promise.all( translationList.map( (tranlastionData) => {
		const {
			key,
		} = tranlastionData;

		if ( ! key ) {
			return createTranslationItem({data: tranlastionData});
		}

		return updateTranslationItem({ key, data: tranlastionData});
	}));
}
