import { ROLE_ADMIN } from 'data/constants';

export function isAdmin( roles ) {
	if( ! roles ) {
		return false;
	}

	return roles.includes(ROLE_ADMIN);
}

export function getActiveOrgs( orgs ) {
	if ( ! orgs ) {
		return [];
	}

	return orgs.filter( (org) => ! org.deleted )
}

export function isTiedToActiveOrg( orgs, orgKey ) {
	if( !orgs ) {
		return false;
	}

	const activeOrgs = getActiveOrgs( orgs );
	const matchedOrg = activeOrgs.find( (org) => org.key === orgKey );

	if( matchedOrg ) {
		return true;
	}

	return false;
}

export function getOrgUsersHash( users ) {
	const hash = {};

	if ( !users ) {
		return hash;
	}

	users.forEach( (user) => {
		const {
			organizations=[],
			...restProps
		} = user;

		organizations.forEach( ({ key: orgKey }) => {
			if ( !hash[orgKey] ) {
				hash[orgKey] = [];
			}

			hash[orgKey].push(restProps);
		});
	});

	return hash;
}

export function getUserSearchString( users ) {
	if ( !users ) {
		return "";
	}

	return users.reduce( (data, user) => {
		const {
			firstName,
			lastName,
			correspondenceEmailAddress,
		} = user;

		return `${data} ${firstName} ${lastName} ${correspondenceEmailAddress}`;
	}, "");	
}
