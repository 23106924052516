import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { isAdmin } from 'utils/user';
import { getActiveOrgs } from 'utils/org';

import {
	getPathLogin,
	getPathOrgHome,
	getPathAdminHome,
	getPathSelectOrg,
} from 'Routes/paths';

class DefaultRedirect extends Component {
	render() {
		const {
			currentUser,
			currentOrg,
		} = this.props;

		// NO USER LOGGED IN
		if ( ! currentUser || ! currentUser.key ) {
			return <Navigate to={getPathLogin()} replace />;
		}

		// CURRENT ORG IS SET, REDIRECT ACCORDINGLY
		if ( currentOrg && currentOrg.key ) {
			return <Navigate to={getPathOrgHome(currentOrg.key)} replace />;
		}

		// CURRENT USER IS ADMIN, REDIRECT TO ADMIN HOME
		if( isAdmin( currentUser.roles ) ) {
			return <Navigate to={getPathAdminHome()} replace />;
		}

		const activeOrgs = getActiveOrgs( currentUser.organizations );
		if( activeOrgs.length === 1 ) {
			return <Navigate to={getPathOrgHome(activeOrgs[0].key)} replace />;
		}

		return <Navigate to={getPathSelectOrg()} replace />;
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.auth.currentUser,
		currentOrg: state.auth.currentOrg,
	}
}

DefaultRedirect.propTypes = {
	currentUser: PropTypes.object.isRequired,    // connect: mapStateToProps
	currentOrg: PropTypes.object.isRequired,     // connect: mapStateToProps
}

DefaultRedirect = connect(mapStateToProps)(DefaultRedirect);

export default DefaultRedirect;
