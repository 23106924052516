import { throwIfMissing } from 'utils';
import {
	getData,
	postData,
	putData,
	deleteData,
} from 'utils/api';

const END_POINT = 'admin/buyers';
const ACTION_ROOT = 'buyers';

const MSG_SAVE_SUCCESS = `Buyer Saved`;
const MSG_DELETE_SUCCESS = `Buyer Deleted`;

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const CLEAR_LIST							= `${ACTION_ROOT}/CLEAR_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllBuyers = () => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: END_POINT,
			action: SET_LIST,
		});
	}
}

export function clearAllBuyers() {
	return {
		type: CLEAR_LIST,
	}
}

export function createBuyer( data, formName ) {
	return async (dispatch) => {
		return await postData({
			dispatch,
			data,
			formName,
			endpoint: END_POINT,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function setActiveBuyerEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveBuyer() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveBuyer( key ) {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
		});
	}
}

export function updateActiveBuyer( key, data, formName ) {
	return async (dispatch) => {
		return await putData({
			dispatch,
			data,
			formName,
			endpoint: `${END_POINT}/${key}`,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function deleteBuyer( key ) {
	return async (dispatch) => {
		return await deleteData({
			endpoint: `${END_POINT}/${key}`,
			successMsg: MSG_DELETE_SUCCESS,
		});
	}
}

export const linkBuyerAndSupplier = ({
	buyerKey=throwIfMissing(),
	supplierKey=throwIfMissing(),
	formName,
}) => {
	return async (dispatch) => {
		return await putData({
			data: {},
			formName,
			endpoint: `${END_POINT}/${buyerKey}/supplier/${supplierKey}`,
			successMsg: "Buyer and Supplier Linked",
		});	
	}
}

export const unlinkBuyerAndSupplier = ({
	buyerKey=throwIfMissing(),
	supplierKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			endpoint: `${END_POINT}/${buyerKey}/supplier/${supplierKey}`,
			successMsg: "Buyer and Supplier Unlinked",
		});	
	}
}
