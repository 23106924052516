import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';

import auth from '../modules/auth';
import buyers from '../modules/admin/buyers.js';
import clientContent from '../modules/admin/client-content.js';
import documentTypes from '../modules/admin/document-types';
import growers from '../modules/admin/growers.js';
import orgs from '../modules/admin/orgs.js';
import orgSubscriptions  from '../modules/admin/org-subscriptions.js';
import orgSurveyResponses  from '../modules/admin/org-survey-responses.js';
import growerCertification from '../modules/admin/grower-certification.js';
import growerDWA from '../modules/admin/grower-dwa.js';
import programs from '../modules/admin/programs.js';
import programGrowers  from '../modules/admin/program-growers.js';
import programSuppliers  from '../modules/admin/program-suppliers.js';
import standardItems  from '../modules/admin/standard-items';
import suppliers from '../modules/admin/suppliers.js';
import surveys  from '../modules/admin/surveys';
import surveyAnswers  from '../modules/admin/survey-answers';
import surveyQuestions  from '../modules/admin/survey-questions';
import surveyQuestionFeedback  from '../modules/admin/survey-question-feedback';
import surveyQuestionResourceLinks  from '../modules/admin/survey-question-resource-links';
import surveySections  from '../modules/admin/survey-sections';
import surveyTags  from '../modules/admin/survey-tags';
import translationItems  from '../modules/admin/translation-items';
import users from '../modules/users';

export default combineReducers({
	auth,
	buyers,
	clientContent,
	documentTypes,
	form,
	growers,
	orgs,
	orgSubscriptions,
	orgSurveyResponses,
	growerCertification,
	growerDWA,
	programs,
	programGrowers,
	programSuppliers,
	standardItems,
	suppliers,
	surveys,
	surveyAnswers,
	surveyQuestions,
	surveyQuestionFeedback,
	surveyQuestionResourceLinks,
	surveySections,
	surveyTags,
	translationItems,
	users,
	settings: settingsReducer,
	theme: themesReducer,
});
