const SESSION_TOKEN_KEY = 'token';

export function setSessionToken(token) {
  localStorage.setItem( SESSION_TOKEN_KEY, token );
}

export function getSessionToken() {
  return localStorage.getItem( SESSION_TOKEN_KEY );
}

export function removeSessionToken() {
  localStorage.removeItem( SESSION_TOKEN_KEY );
}
