import { throwIfMissing } from 'utils';

import {
	getData,
} from 'utils/api';

const ACTION_ROOT = 'program-suppliers';

/* ACTIONS */
const SET_SUPPLIERS             = `${ACTION_ROOT}/SET_SUPPLIERS`;
const SET_SUPPLIER_SUMMARY      = `${ACTION_ROOT}/SET_SUPPLIER_SUMMARY`;
const SET_SUPPLIER_DETAIL       = `${ACTION_ROOT}/SET_SUPPLIER_DETAIL`;
const CLEAR_SUPPLIERS           = `${ACTION_ROOT}/CLEAR_SUPPLIERS`;

const initialState = {
	suppliers: [],
	supplierSummaryHash: {},
	supplierDetailHash: {},
};

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_SUPPLIERS:
			return { ...state, suppliers: action.payload };
		case SET_SUPPLIER_SUMMARY:
			return { ...state, supplierSummaryHash: action.payload };
		case SET_SUPPLIER_DETAIL:
			return { ...state, supplierDetailHash: action.payload };
		case CLEAR_SUPPLIERS:
			return { ...state, suppliers: initialState.suppliers };
		default:
			return state;
	}
}

export const getSuppliers = ({
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const response = await getData({
			endpoint: `reports/programs/${programKey}/supplierSummary`,
			dispatch,
			action: SET_SUPPLIER_SUMMARY,
		});

		const {
			data,
			error,
		} = response;

		if (error) {
			return response;
		}

		const supplierKeys = Object.keys(data);

		const payload = supplierKeys.map( (key) => data[key]);
		
		if (dispatch) {
			dispatch({
				payload,
				type: SET_SUPPLIERS,
			});
		}

		return payload;
	}
}

export const getSupplierDetails = ({
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			endpoint: `reports/programs/${programKey}/supplierDetail`,
			dispatch,
			action: SET_SUPPLIER_DETAIL,
		});
	}
}

export function clearSuppliers() {
	return {
		type: CLEAR_SUPPLIERS,
	}
}
