import { throwIfMissing } from 'utils';

import {
	getData,
	putData,
	deleteData,
} from 'utils/api';


const endpoint = (organizationKey, programKey) => {
	return `organizations/${organizationKey}/program/${programKey}/certification`
};

const ACTION_ROOT = 'grower-certification';

const MSG_SAVE_SUCCESS = `Certification Saved`;
const MSG_DELETE_SUCCESS = `Certification Deleted`;

/* ACTIONS */
const SET_ACTIVE              = `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE            = `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE   = `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;
const SET_CODES_LIST          = `${ACTION_ROOT}/SET_CODES_LIST`;

const initialState = {
	active: {},
	editState: false,
	codesList: [],
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		case SET_CODES_LIST:
			return {...state, codesList: action.payload }
		default:
			return state;
	}
}

export function clearActiveCertification() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getCertification = ({
	organizationKey=throwIfMissing(),
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: endpoint(organizationKey, programKey),
			action: SET_ACTIVE,
		});
	}
}

export const updateCertification = ({
	organizationKey=throwIfMissing(),
	programKey=throwIfMissing(),
	data,
	formName,
}) => {
	return async (dispatch) => {
		return await putData({
			dispatch,
			data,
			formName,
			endpoint: endpoint(organizationKey, programKey),
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export const deleteCertification = ({
	organizationKey=throwIfMissing(),
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: endpoint(organizationKey, programKey),
			action: CLEAR_ACTIVE,
			successMsg: MSG_DELETE_SUCCESS,
		});
	}
}

export const getCertificationCodesList = ({
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `programs/${programKey}/certificationCodes`,
			action: SET_CODES_LIST,
		});
	}
}
