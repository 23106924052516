import React from 'react';
import PropTypes from 'prop-types';

import ReadControl from '../ReadControl';

/** Renders a read view of control data when not in edit mode */
export function ReadView( props={} ) {
	const {
		input,
		type,
		defaultValue,
	} = props;

	if ( ! input.value && defaultValue ) {
		return (
			<ReadControl muted>
				{ defaultValue }
			</ReadControl>
		);
	}

	if ( input.value && type === "password" ) {
		return (
			<ReadControl>
				******
			</ReadControl>
		);
	}

	return (
		<ReadControl>
			{ input.value }
		</ReadControl>
	);
}

ReadView.propTypes = {
	/** input.value used to display value, (usually from ReduxForm.field) */
	input: PropTypes.object.isRequired,

	/** if type = password, value shown as "******" */
	type: PropTypes.string,

	/** optional value shown in muted text if ! input.value */
	defaultValue: PropTypes.string,
}

export default ReadView;
