import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { AUTH_USER, getCurrentUser } from 'store/modules/auth';
import { getSessionToken } from 'utils/localStorage';

import App from './App';
import reportWebVitals from './reportWebVitals';

import configureStore from './store/store';
const store = configureStore();

const init = async () => {
	const token = getSessionToken();
	if( token ) {
		store.dispatch( { type: AUTH_USER } );
		await getCurrentUser(store.dispatch);
	}

	renderApp();
}

function renderApp() {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('app')
	);
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
