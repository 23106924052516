import { throwIfMissing } from 'utils';
import {
	getData,
	postData,
	putData,
	deleteData,
} from 'utils/api';

const endpoint = (orgKey) => {
	return `organizations/${orgKey}/subscriptions`
};
const ACTION_ROOT = 'orgSubscriptions';

const MSG_SAVE_SUCCESS = `Subscription Saved`;
const MSG_DELETE_SUCCESS = `Subscription Deleted`;

/* ACTIONS */
const SET_LIST                = `${ACTION_ROOT}/SET_LIST`;
const CLEAR_LIST              = `${ACTION_ROOT}/CLEAR_LIST`;
const SET_ACTIVE              = `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE            = `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE   = `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_LIST:
			return { ...state, list: [] };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllSubscriptions = ({
	orgKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: endpoint(orgKey),
			action: SET_LIST,
		});
	}
}

export function clearAllSubscriptions() {
	return {
		type: CLEAR_LIST,
	}
}

export function createSubscription({
	orgKey=throwIfMissing(),
	data=throwIfMissing(),
	formName,
}) {
	return async (dispatch) => {
		return await postData({
			dispatch,
			data,
			formName,
			endpoint: endpoint(orgKey),
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function setActiveSubscriptionEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveSubscription() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveSubscription({
	orgKey=throwIfMissing(),
	key=throwIfMissing(),
}) {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: `${endpoint(orgKey)}/${key}`,
			action: SET_ACTIVE,
		});
	}
}

export function updateActiveSubscription({
	orgKey=throwIfMissing(),
	key=throwIfMissing(),
	data=throwIfMissing(),
	formName,
}) {
	return async (dispatch) => {
		return await putData({
			dispatch,
			data,
			formName,
			endpoint: `${endpoint(orgKey)}/${key}`,
			action: SET_ACTIVE,
			successMsg: MSG_SAVE_SUCCESS,
		});
	}
}

export function deleteSubscription({
	orgKey=throwIfMissing(),
	key=throwIfMissing(),
}) {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: `${endpoint(orgKey)}/${key}`,
			action: CLEAR_ACTIVE,
			successMsg: MSG_DELETE_SUCCESS,
		});
	}
}

