import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Collapse from 'react-bootstrap/Collapse';
import Badge from 'react-bootstrap/Badge';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span>{item.heading}</span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span>{item.name}</span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span>{item.name}</span>
        </div>
        <Collapse in={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

/** map menu config to string to determine which element to render */
const itemType = item => {
		if (item.heading) return 'heading';
		if (!item.submenu) return 'menu';
		if (item.submenu) return 'submenu';
}

const Menu = (props) => {
	const {
		data,
		onToggleItemCollapse,
	} = props;

	return data.map((item, i) => {
			// heading
			if(itemType(item) === 'heading')
					return (
							<SidebarItemHeader item={item} key={i} />
					)
			else {
					if(itemType(item) === 'menu')
							return (
									<SidebarItem isActive={ item.isActive } item={item} key={`${item.name}${item.path}`} />
							)
					if(itemType(item) === 'submenu')
							return [
									<SidebarSubItem item={item} isOpen={item.isOpen} handler={ () => onToggleItemCollapse(item.name) } isActive={ item.isActive } key={`${item.name}${item.path}`}>
											<SidebarSubHeader item={item} key={i}/>
											{
													item.submenu.map((subitem, i) => {
														return (
															<SidebarItem key={`${subitem.name}${subitem.path}`} item={subitem} isActive={ subitem.isActive } />
														);
													})
											}
									</SidebarSubItem>
							]
			}
			return null; // unrecognized item
	})
}

Menu.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({

	})).isRequired,
	onToggleItemCollapse: PropTypes.func.isRequired,
}

export default Menu;
