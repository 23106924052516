/* eslint no-console: 0 */

import {
	getData,
	postData,
	putData,
	putString,
	UNKNOWN_ERROR_MSG,
	showSuccessMessage,
	showErrorMessage,
	showInfoMessage,
} from 'utils/api';

import { getCurrentBaseUrl } from 'utils';
import {
	setSessionToken,
	removeSessionToken,
} from 'utils/localStorage';

export const AUTH_USER = 'auth/AUTH_USER';
const AUTH_ERROR = 'auth/AUTH_ERROR';
const UNAUTH_USER = 'auth/UNAUTH_USER';
const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
const SET_CURRENT_USER_EDIT_STATE = 'auth/SET_CURRENT_USER_EDIT_STATE';
export const SET_CURRENT_FOUNDATION = 'auth/SET_CURRENT_FOUNDATION';
export const SET_CURRENT_ORG = 'auth/SET_CURRENT_ORG';
export const SET_CURRENT_PROGRAM = 'auth/SET_CURRENT_PROGRAM';
export const SET_CURRENT_LOCATION = 'auth/SET_CURRENT_LOCATION';
const SET_INVITE_TOKEN_ERROR = 'auth/SET_INVITE_TOKEN_ERROR';
const RESET_PASSWORD_TOKEN_ERROR = 'auth/RESET_PASSWORD_TOKEN_ERROR';

const initialState = {
	authenticated: false,
	currentUser: {},
	currentUserEditState: false,
	currentFoundation: {},
	currentOrg: {},
	currentProgram: {},
	currentLocation: '',
	errorMessage: '',
	resetPasswordTokenError: false,
	inviteTokenError: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case AUTH_USER:
			return { ...state, authenticated: true, errorMessage: '' };
		case UNAUTH_USER:
			return initialState;
		case AUTH_ERROR:
			return { ...state, errorMessage: action.payload };
		case SET_CURRENT_USER:
			return { ...state, currentUser: action.payload, currentUserEditState: false };
		case SET_CURRENT_USER_EDIT_STATE:
			return { ...state, currentUserEditState: action.payload };
		case SET_CURRENT_FOUNDATION:
			return { ...state, currentFoundation: action.payload };
		case SET_CURRENT_ORG:
			return { ...state, currentOrg: action.payload };
		case SET_CURRENT_PROGRAM:
			return { ...state, currentProgram: action.payload };
		case SET_CURRENT_LOCATION:
			return { ...state, currentLocation: action.payload };
		case RESET_PASSWORD_TOKEN_ERROR:
			return { ...state, resetPasswordTokenError: action.payload };
		case SET_INVITE_TOKEN_ERROR:
			return { ...state, inviteTokenError: action.payload };
		default:
			return state;
	}
}

export function loginUser(data) {
	return async (dispatch) => {
		const response = await postData({
			dispatch,
			data,
			endpoint: 'sessions/login',
			headers: {},
		});

		if( response.error ) {
			const { message=UNKNOWN_ERROR_MSG } = response;
			dispatch( authError(message) );
			return response;
		}

		if( !response.data.success ) {
			const { message=UNKNOWN_ERROR_MSG } = response.data;
			dispatch( authError( message ) );
			return response;
		}

		dispatch({ type: AUTH_USER });
		setSessionToken(response.data.sessionToken);
		await getCurrentUser(dispatch);
		return response;
	}
}

export function logoutUser() {
	removeSessionToken();
	return { type: UNAUTH_USER };
}

export function authError( error ) {
	return {
		type: AUTH_ERROR,
		payload: error
	}
}

export function clearAuthError() {
	return {
		type: AUTH_ERROR,
		payload: '',
	}
}

export const getCurrentUser = async (dispatch) => {
	// GET CURRENT USER
	const response = await getData({
		dispatch,
		endpoint: 'users/current',
		action: SET_CURRENT_USER,
	});

	if (response.error) {
		dispatch(	logoutUser() );
	}
}

export function setCurrentOrg( orgKey ) {
	return async (dispatch) => {
		const response = await getData({
			dispatch,
			endpoint: `admin/organizations/${orgKey}`,
			action: SET_CURRENT_ORG,
		});

		showInfoMessage(`You are now accessing the system as ${response.data.name}`);

		return response;
	}
}

export function setCurrentOrgWithOrg( org ) {
	return {
		type: SET_CURRENT_ORG,
		payload: org,
	}
}

export function clearCurrentOrg() {
	return {
		type: SET_CURRENT_ORG,
		payload: {},
	}
}

export function setActiveProgram(programKey) {
	return async ( dispatch ) => {
		return await getData({
			dispatch,
			endpoint: `programs/${programKey}`,
			action: SET_CURRENT_PROGRAM,
		});
	}
}

export function clearActiveProgram() {
	return {
		type: SET_CURRENT_PROGRAM,
		payload: {},
	}
}

export const confirmInviteToken = (token) => {
	return async (dispatch) => {
		dispatch({
			type: SET_INVITE_TOKEN_ERROR,
			payload: false,
		});

		const response = await getData({
			endpoint: `users/acceptInvitation/${token}`,
			headers: {},
		});

		if ( response.error || !response.data.id ) {
			dispatch({
				type: SET_INVITE_TOKEN_ERROR,
				payload: true,
			});
		}

		return response;
	}
}

export function acceptInvite( token, data ) {
	return async (dispatch) => {
		const response = await putData({
			dispatch,
			data,
			endpoint: `users/acceptInvitation/${token}`,
			headers: {},
		});

		if( response.data.success ) {
			showSuccessMessage("Your account has been created.", "Admin Management");
		}
		else {
			const errorMsg = ( response.data.message ) ? response.data.message : UNKNOWN_ERROR_MSG;
			showErrorMessage(errorMsg);
		}

		return response;
	}
}

export function confirmResetPasswordToken( token ) {
	return async (dispatch) => {
		const response = await getData({
			endpoint: `passwords/reset/${token}`,
			headers: {},
		});

		if ( response.error ) {
			console.error( response.message );
			dispatch({
				type: RESET_PASSWORD_TOKEN_ERROR,
				payload: true,
			});

			return response;
		}

		dispatch({
			type: RESET_PASSWORD_TOKEN_ERROR,
			payload: !response.data.success,
		});

		return response;
	}
}

export function setPasswordWithToken( token, password ) {
	return async (dispatch) => {
		const response = await putString({
			data: password,
			endpoint: `passwords/reset/${token}`,
			headers: { // NO AUTH HEADERS FOR THIS REQUEST BUT STRING DATA REQUIRES SPECIFYING JSON CONTENT TYPE
				"Content-Type": "application/json"
			},
		});

		if ( response.data.success ) {
			showSuccessMessage("You have successfully updated your password.");
		}
		else {
			const errorMsg = ( response.data.message ) ? response.data.message : UNKNOWN_ERROR_MSG;
			showErrorMessage(errorMsg);
			console.error(response);
		}

		return response;
	}
}

export function requestResetPassword( formProps, resetPath, formName ){
	return async (dispatch) => {

		const data = {
			emailAddress: formProps.emailAddress,
			url: `${getCurrentBaseUrl()}${resetPath}`,
		}

		return await postData({
			data,
			dispatch,
			formName,
			endpoint: 'passwords/sendResetEmail',
			headers: {},
			successMsg: "Please check your email for instructions.",
		});
	}
}
