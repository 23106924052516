import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const BasePage = props =>(
	<DndProvider backend={HTML5Backend}>
		<div className="wrapper">
			{ props.children }
		</div>
	</DndProvider>
)

BasePage.propTypes = {
	children: PropTypes.node,
}

export default BasePage;
