import React, { Suspense, lazy } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
  Routes as RRRoutes,
  Route,
	useLocation,
} from "react-router-dom";

import {
	PageInit,
	BypassIfAuthInit,
	AdminPageInit,
	OrgPageInit,
	AuthPageInit,
} from 'components/HigherOrder/PageInit';

/* loader component for Suspense*/
import PageLoader from 'components/Theme/Common/PageLoader';

import Base from 'components/Theme/Layout/Base';
import BasePage from 'components/Theme/Layout/BasePage';

import DefaultRedirect from './DefaultRedirect';
// List of routes that uses the unknown user page layout
// listed here to Switch between layouts
// depending on the current pathname
import * as routePaths from './paths';

// UNKNOWN USER PAGES
const Login = lazy(() => import('components/Pages/Unknown/Login'));
const Logout = lazy(() => import('components/Pages/Unknown/Logout'));
const PasswordRecover = lazy(() => import('components/Pages/Unknown/PasswordRecover'));
const PasswordReset = lazy(() => import('components/Pages/Unknown/PasswordReset'));
const AdminAccept = lazy(() => import('components/Pages/Unknown/AdminAccept'));

// SUPER ADMIN PAGES
const AdminDashboard = lazy(() => import('components/Pages/Authorized/Admin/Dashboard'));
const AdminsList = lazy(() => import('components/Pages/Authorized/Admin/Admins/List'));
const AdminsDetail = lazy(() => import('components/Pages/Authorized/Admin/Admins/Detail'));
const GrowersList = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Growers/List'));
const GrowersDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Growers/Detail'));
const GrowersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Growers/AddNew'));
const GrowersUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Growers/UserDetail'));
const SuppliersList = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Suppliers/List'));
const SuppliersDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Suppliers/Detail'));
const SuppliersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Suppliers/AddNew'));
const SuppliersUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Suppliers/UserDetail'));
const BuyersList = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Buyers/List'));
const BuyersDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Buyers/Detail'));
const BuyersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Buyers/AddNew'));

const DocumentTypesList = lazy(() => import('components/Pages/Authorized/Admin/DocumentTypes/List'));
const DocumentTypesAddNew = lazy(() => import('components/Pages/Authorized/Admin/DocumentTypes/AddNew'));
const DocumentTypesDetail = lazy(() => import('components/Pages/Authorized/Admin/DocumentTypes/Detail'));
const StandardItemsList = lazy(() => import('components/Pages/Authorized/Admin/StandardItems/List'));
const StandardItemsAddNew = lazy(() => import('components/Pages/Authorized/Admin/StandardItems/AddNew'));
const StandardItemsDetail = lazy(() => import('components/Pages/Authorized/Admin/StandardItems/Detail'));
const ProgramsList = lazy(() => import('components/Pages/Authorized/Admin/Programs/List'));
const ProgramConfig = lazy(() => import('components/Pages/Authorized/Admin/Program/Config'));
const ProgramReports = lazy(() => import('components/Pages/Authorized/Admin/Program/Reports'));
const ProgramQuestionsList = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/List'));
const ProgramQuestionsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/AddNew'));
const ProgramQuestionsDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/Detail'));
const ProgramQuestionFeedbackAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/Feedback/AddNew'));
const ProgramQuestionFeedbackDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/Feedback/Detail'));
const ProgramQuestionResourceLinksAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/ResourceLinks/AddNew'));
const ProgramQuestionResourceLinksDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Questions/ResourceLinks/Detail'));
const ProgramSectionsList = lazy(() => import('components/Pages/Authorized/Admin/Program/Sections/List'));
const ProgramSectionsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Sections/AddNew'));
const ProgramSectionsDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Sections/Detail'));
const ProgramAnswersList = lazy(() => import('components/Pages/Authorized/Admin/Program/Answers/List'));
const ProgramAnswersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Answers/AddNew'));
const ProgramAnswersDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Answers/Detail'));
const ProgramTagsList = lazy(() => import('components/Pages/Authorized/Admin/Program/Tags/List'));
const ProgramTagsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Tags/AddNew'));
const ProgramTagsDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Tags/Detail'));
const ProgramGrowersList = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/List'));
const ProgramGrowersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/AddNew'));
const ProgramGrowersDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/Detail'));
const ProgramGrowersUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/UserDetail'));
const ProgramGrowersSubscriptionAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/Subscription/AddNew'));
const ProgramGrowersSubscriptionDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Growers/Subscription/Detail'));
const ProgramSuppliersList = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/List'));
const ProgramSuppliersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/AddNew'));
const ProgramSuppliersDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/Detail'));
const ProgramSuppliersUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/UserDetail'));
const ProgramSuppliersSubscriptionAddNew = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/Subscription/AddNew'));
const ProgramSuppliersSubscriptionDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/Suppliers/Subscription/Detail'));
const ProgramClientContentList = lazy(() => import('components/Pages/Authorized/Admin/Program/ClientContent/List'));
const ProgramClientContentDetail = lazy(() => import('components/Pages/Authorized/Admin/Program/ClientContent/Detail'));

// LEGACY SURVEYS - V1
const ProgramV1QuestionsList = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Questions/List'));
const ProgramV1QuestionsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Questions/AddNew'));
const ProgramV1QuestionsDetail = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Questions/Detail'));
const ProgramV1SectionsList = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Sections/List'));
const ProgramV1SectionsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Sections/AddNew'));
const ProgramV1SectionsDetail = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Sections/Detail'));
const ProgramV1GrowersList = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Growers/List'));
const ProgramV1GrowersAddNew = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Growers/AddNew'));
const ProgramV1GrowersDetail = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Growers/Detail'));
const ProgramV1GrowersUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Legacy/ProgramV1/Growers/UserDetail'));


// ORG PAGES
const OrgDashboard = lazy(() => import('components/Pages/Authorized/Org/Dashboard'));
const OrgUsers = lazy(() => import('components/Pages/Authorized/Org/Users/List'));

// OTHER PAGES
const SelectOrg = lazy(() => import('components/Pages/Authorized/SelectOrg'));

const Routes = () => {
	const location = useLocation();

	const currentKey = location.pathname.split('/')[1] || '/';
	const timeout = { enter: 500, exit: 500 };

	// Animations supported
	//      'rag-fadeIn'
	//      'rag-fadeInRight'
	//      'rag-fadeInLeft'

  const animationName = 'rag-fadeIn'

	if(routePaths.UNKNOWN_USER_PATHS.indexOf(location.pathname) > -1) {
		return (
			// Page Layout component wrapper
			<BasePage>
				<Suspense fallback={<PageLoader/>}>
					<RRRoutes>
						<Route path={ routePaths.getPathLogin() } element={<BypassIfAuthInit ComposedComponent={Login} />}/>
						<Route path={ routePaths.getPathLogout() } element={<PageInit ComposedComponent={Logout} />}/>
						<Route path={ routePaths.getPathPasswordRecover() } element={<BypassIfAuthInit ComposedComponent={PasswordRecover} /> }/>
						<Route path={ routePaths.getPathPasswordReset() } element={<PageInit ComposedComponent={PasswordReset} /> }/>
						<Route path={ routePaths.getPathAdminAccept() } element={<PageInit ComposedComponent={AdminAccept} /> }/>
					</RRRoutes>
				</Suspense>
			</BasePage>
		)
	}
	else {
		return (
			<Base>
				<TransitionGroup>
					<CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
						<div>
							<Suspense fallback={<PageLoader/>}>
								<RRRoutes>
									<Route path={ routePaths.getPathAdminDashboard() } element={<AdminPageInit ComposedComponent={AdminDashboard} /> }/>
									<Route path={ routePaths.getPathGrowers() } exact element={ <AdminPageInit ComposedComponent={GrowersList} /> } />
									<Route path={ routePaths.getPathGrowersAddNew() } exact element={ <AdminPageInit ComposedComponent={GrowersAddNew} /> } />
									<Route path={ routePaths.getPathGrowersUserDetail(':adminOrgKey', ':key') } exact element={ <AdminPageInit ComposedComponent={GrowersUserDetail} /> } />
									<Route path={ routePaths.getPathGrowers(':key') } exact element={ <AdminPageInit ComposedComponent={GrowersDetail} /> } />
									<Route path={ routePaths.getPathSuppliers() } exact element={ <AdminPageInit ComposedComponent={SuppliersList} /> } />
									<Route path={ routePaths.getPathSuppliersAddNew() } exact element={ <AdminPageInit ComposedComponent={SuppliersAddNew} /> } />
									<Route path={ routePaths.getPathSuppliersUserDetail(':adminOrgKey', ':key') } exact element={ <AdminPageInit ComposedComponent={SuppliersUserDetail} /> } />
									<Route path={ routePaths.getPathSuppliers(':key') } exact element={ <AdminPageInit ComposedComponent={SuppliersDetail} /> } />
									<Route path={ routePaths.getPathBuyers() } exact element={ <AdminPageInit ComposedComponent={BuyersList} /> } />
									<Route path={ routePaths.getPathBuyersAddNew() } exact element={ <AdminPageInit ComposedComponent={BuyersAddNew} /> } />
									<Route path={ routePaths.getPathBuyers(':key') } exact element={ <AdminPageInit ComposedComponent={BuyersDetail} /> } />
									<Route path={ routePaths.getPathSystemAdmins() } exact element={ <AdminPageInit ComposedComponent={AdminsList} /> } />
									<Route path={ routePaths.getPathSystemAdmins(':key') } exact element={ <AdminPageInit ComposedComponent={AdminsDetail} /> } />

									<Route path={ routePaths.getPathDocumentTypes() } exact element={ <AdminPageInit ComposedComponent={DocumentTypesList} /> } />
									<Route path={ routePaths.getPathDocumentTypesAddNew() } exact element={ <AdminPageInit ComposedComponent={DocumentTypesAddNew} /> } />
									<Route path={ routePaths.getPathDocumentTypes(':documentTypeKey') } exact element={ <AdminPageInit ComposedComponent={DocumentTypesDetail} /> } />
									<Route path={ routePaths.getPathStandardItems(':standardKey') } exact element={ <AdminPageInit ComposedComponent={StandardItemsList} /> } />
									<Route path={ routePaths.getPathStandardItemsAddNew(':standardKey') } exact element={ <AdminPageInit ComposedComponent={StandardItemsAddNew} /> } />
									<Route path={ routePaths.getPathStandardItems(':standardKey', ':standardItemKey') } exact element={ <AdminPageInit ComposedComponent={StandardItemsDetail} /> } />

									<Route path={ routePaths.getPathPrograms() } exact element={ <AdminPageInit ComposedComponent={ProgramsList} /> } />
									<Route path={ routePaths.getPathProgramConfig(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramConfig} /> } />
									<Route path={ routePaths.getPathProgramReports(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramReports} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestions(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionsList} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestionsAddNew(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionsAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestions(':programKey', ':surveyKey',':questionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionsDetail} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestionFeedbackAddNew(':programKey', ':surveyKey',':questionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionFeedbackAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestionFeedback(':programKey', ':surveyKey',':questionKey',':questionFeedbackKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionFeedbackDetail} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestionResourceLinksAddNew(':programKey', ':surveyKey',':questionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionResourceLinksAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveyQuestionResourceLinks(':programKey', ':surveyKey',':questionKey',':resourceLinkKey') } exact element={ <AdminPageInit ComposedComponent={ProgramQuestionResourceLinksDetail} /> } />
									<Route path={ routePaths.getPathProgramSurveySections(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSectionsList} /> } />
									<Route path={ routePaths.getPathProgramSurveySectionsAddNew(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSectionsAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveySections(':programKey', ':surveyKey', ':sectionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSectionsDetail} /> } />
									<Route path={ routePaths.getPathProgramSurveyAnswers(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramAnswersList} /> } />
									<Route path={ routePaths.getPathProgramSurveyAnswersAddNew(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramAnswersAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveyAnswers(':programKey', ':surveyKey',':answerKey') } exact element={ <AdminPageInit ComposedComponent={ProgramAnswersDetail} /> } />
									<Route path={ routePaths.getPathProgramSurveyTags(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramTagsList} /> } />
									<Route path={ routePaths.getPathProgramSurveyTagsAddNew(':programKey', ':surveyKey') } exact element={ <AdminPageInit ComposedComponent={ProgramTagsAddNew} /> } />
									<Route path={ routePaths.getPathProgramSurveyTags(':programKey', ':surveyKey',':tagKey') } exact element={ <AdminPageInit ComposedComponent={ProgramTagsDetail} /> } />
									<Route path={ routePaths.getPathProgramGrowers(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersList} /> } />
									<Route path={ routePaths.getPathProgramGrowersAddNew(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersAddNew} /> } />
									<Route path={ routePaths.getPathProgramGrowerSurveyResponse(':programKey',':organizationKey',':surveyResponseKey') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersDetail} /> } />
									<Route path={ routePaths.getPathProgramGrowerUserDetail(':programKey',':organizationKey',':surveyResponseKey', ':key') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersUserDetail} /> } />
									<Route path={ routePaths.getPathProgramGrowerSubscriptionAddNew(':programKey',':organizationKey',':surveyResponseKey') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersSubscriptionAddNew} /> } />
									<Route path={ routePaths.getPathProgramGrowerSubscriptionDetail(':programKey',':organizationKey',':surveyResponseKey', ':key') } exact element={ <AdminPageInit ComposedComponent={ProgramGrowersSubscriptionDetail} /> } />
									<Route path={ routePaths.getPathProgramSuppliers(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersList} /> } />
									<Route path={ routePaths.getPathProgramSuppliersAddNew(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersAddNew} /> } />
									<Route path={ routePaths.getPathProgramSuppliers(':programKey',':organizationKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersDetail} /> } />
									<Route path={ routePaths.getPathProgramSupplierUserDetail(':programKey',':organizationKey', ':key') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersUserDetail} /> } />
									<Route path={ routePaths.getPathProgramSupplierSubscriptionAddNew(':programKey',':organizationKey',':surveyResponseKey') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersSubscriptionAddNew} /> } />
									<Route path={ routePaths.getPathProgramSupplierSubscriptionDetail(':programKey',':organizationKey',':key') } exact element={ <AdminPageInit ComposedComponent={ProgramSuppliersSubscriptionDetail} /> } />
									<Route path={ routePaths.getPathProgramClientContent(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramClientContentList} /> } />
									<Route path={ routePaths.getPathProgramClientContentDetail(':programKey', ':key') } exact element={ <AdminPageInit ComposedComponent={ProgramClientContentDetail} /> } />

									<Route path={ routePaths.getPathProgramV1Config(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramConfig} /> } />
									<Route path={ routePaths.getPathProgramV1Questions(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1QuestionsList} /> } />
									<Route path={ routePaths.getPathProgramV1QuestionsAddNew(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1QuestionsAddNew} /> } />
									<Route path={ routePaths.getPathProgramV1Questions(':programKey',':questionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1QuestionsDetail} /> } />
									<Route path={ routePaths.getPathProgramV1Sections(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1SectionsList} /> } />
									<Route path={ routePaths.getPathProgramV1SectionsAddNew(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1SectionsAddNew} /> } />
									<Route path={ routePaths.getPathProgramV1Sections(':programKey',':sectionKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1SectionsDetail} /> } />
									<Route path={ routePaths.getPathProgramV1Growers(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1GrowersList} /> } />
									<Route path={ routePaths.getPathProgramV1GrowersAddNew(':programKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1GrowersAddNew} /> } />
									<Route path={ routePaths.getPathProgramV1GrowerSurveyResponse(':programKey',':organizationKey',':surveyResponseKey') } exact element={ <AdminPageInit ComposedComponent={ProgramV1GrowersDetail} /> } />
									<Route path={ routePaths.getPathProgramV1GrowerUserDetail(':programKey',':organizationKey',':surveyResponseKey', ':key') } exact element={ <AdminPageInit ComposedComponent={ProgramV1GrowersUserDetail} /> } />

									<Route path={ routePaths.getPathOrgDashboard(':orgKey') } element={ <OrgPageInit ComposedComponent={OrgDashboard} /> }/>
									<Route path={ routePaths.getPathOrgUsers(':orgKey') } element={ <OrgPageInit ComposedComponent={OrgUsers} /> }/>

									<Route path={ routePaths.getPathSelectOrg() } element={<AuthPageInit ComposedComponent={SelectOrg} />}/>

									<Route path="*" element={ <DefaultRedirect /> } />

								</RRRoutes>
							</Suspense>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</Base>
		)
	}
}

export default Routes;
