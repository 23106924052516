import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { showError } from 'utils';
import Feedback from '../Feedback';
import HelpText from '../HelpText';

export const Control = (props) => {
	const {
		input,
		controlProps,
		controlType,
		meta,
		placeholder,
		helpText,
	} = props;

	return (
		<React.Fragment>
			<Form.Control
				{...controlProps}
				{...input}
				type={ controlType }
				isInvalid={ showError(meta) }
				placeholder={ placeholder }
			/>
			<Feedback meta={ meta } />
			<HelpText message={ helpText } />
		</React.Fragment>
	)
}

Control.propTypes = {
	/** props (usually from ReduxForm.Field) spread to control element */
	input: PropTypes.object.isRequired,

	/** to spread to react-bootstrap Form.Control */
	controlProps: PropTypes.object,

	/** input type of control */
	controlType: PropTypes.string,

	/** placeholder text for control */
	placeholder: PropTypes.string,

	/** help text for control */
	helpText: PropTypes.string,

	/** meta (usually from ReduxForm.Field) to determine error status and message **/
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}).isRequired,
}

export default Control;
