import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import Unauthorized from 'components/Pages/Authorized/Unauthorized';

import { isAdmin, isTiedToActiveOrg } from 'utils/user';

import {
	getPathLogin,
	getPathAdminHome,
} from 'Routes/paths';

export const BYPASS_IF_AUTH = "BYPASS_IF_AUTH";
export const REQUIRE_AUTH = "REQUIRE_AUTH";
export const REQUIRE_ADMIN = "REQUIRE_ADMIN";
export const REQUIRE_ORG = "REQUIRE_ORG";

class PageAuth extends Component {
	state = {
		checkComplete: false,
		userAuthorized: true,
		redirect: null,
	}

	get isAuthenticated() {
		const {
			authenticated,
		} = this.props;

		return (authenticated) ? true : false;
	}

	get isCurrentUserAdmin() {
		const {
			currentUser,
		} = this.props;

		return isAdmin( currentUser.roles );
	}

	get isCurrentUserTiedToOrg() {
		const {
			currentUser,
			params: {
				orgKey,
			},
		} = this.props;

		if( !orgKey ) {
			return true;
		}

		return isTiedToActiveOrg( currentUser.organizations, orgKey );
	}

	componentDidMount() {
		this.checkAuth();
	}

	checkAuth = () => {
		const {
			authType,
		} = this.props;

		switch (authType) {
			case BYPASS_IF_AUTH:
				this.bypassIfAuth();
				break;
			case REQUIRE_AUTH:
				this.requireAuth();
				break;
			case REQUIRE_ADMIN:
				this.requireAdmin();
				break;
			case REQUIRE_ORG:
				this.requireOrg();
				break;
			default:
				this.setState({ checkComplete: true });
				break;
		}
	}

	bypassIfAuth = () => {
		const {
			isAuthenticated,
		} = this;

		if ( isAuthenticated ) {
			this.redirectToAuthenticatedHome();
			return;
		}

		this.setState({ checkComplete: true });
	}

	requireAuth = () => {
		const {
			isAuthenticated,
		} = this;

		if( ! isAuthenticated ) {
			this.redirectToLogin();
			return;
		}

		this.setState({ checkComplete: true });
	}

	requireAdmin= () => {
		const {
			isAuthenticated,
			isCurrentUserAdmin,
		} = this;

		if( ! isAuthenticated ) {
			this.redirectToLogin();
			return;
		}

		this.setUserAuthorizedState( isCurrentUserAdmin );
		this.setState({ checkComplete: true });
	}

	requireOrg = () => {
		const {
			isAuthenticated,
			isCurrentUserAdmin,
			isCurrentUserTiedToOrg,
		} = this;

		if( !isAuthenticated ) {
			this.redirectToLogin();
			return;
		}

		this.setUserAuthorizedState( isCurrentUserAdmin || isCurrentUserTiedToOrg );
		this.setState({ checkComplete: true });
	}

	setUserAuthorizedState = ( value ) => {
		if( this.state.userAuthorized !== value ) {
			this.setState({ userAuthorized: value });
		}
	}

	redirectToLogin = () => {
		this.setState({ redirect: <Navigate to={getPathLogin()} /> });
	}

	redirectToAuthenticatedHome = () => {
		this.setState({ redirect: <Navigate to={getPathAdminHome()} /> });
	}


	render() {
		const {
			state: {
				checkComplete,
				redirect,
				userAuthorized,
			},
			props: {
				children,
			},
		} = this;
		
		if (redirect) {
			return redirect;
		}

		if (!checkComplete) {
			return null;
		}

		if ( ! userAuthorized ) {
			return <Unauthorized />;
		}

		return children;
	}
}

function mapStateToProps(state) {
	return {
		authenticated: state.auth.authenticated,
		currentUser: state.auth.currentUser,
	};
}

PageAuth.propTypes = {
	authType: PropTypes.string.isRequired,
	params: PropTypes.shape({
		orgKey: PropTypes.string,
	}).isRequired,
	children: PropTypes.node,
};

PageAuth = connect(mapStateToProps)(PageAuth);

const RouterWrapper = (props) => {
	const params = useParams();
	return (
		<PageAuth
			{...props}
			params={params}
		/>
	);
}

export const BypassIfAuth = (props) => {
	return (
		<RouterWrapper
			{...props}
			authType={ BYPASS_IF_AUTH }
		/>
	);
}

export const RequireAuth = (props) => {
	return (
		<RouterWrapper
			{...props}
			authType={ REQUIRE_AUTH }
		/>
	);
}

export const RequireAdmin = (props) => {
	return (
		<RouterWrapper
			{...props}
			authType={ REQUIRE_ADMIN }
		/>
	);
}

export const RequireOrg = (props) => {
	return (
		<RouterWrapper
			{...props}
			authType={ REQUIRE_ORG }
		/>
	);
}
