import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

const Base = props => (
	<DndProvider backend={HTML5Backend}>
    <div className="wrapper">
        <Header />

        <Sidebar />

        <section className="section-container">
            { props.children }
        </section>

        <Footer />
    </div>
	</DndProvider>
);

Base.propTypes = {
	children: PropTypes.node,
}

export default Base;
