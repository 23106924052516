import { throwIfMissing } from 'utils';

import {
	getData,
	postData,
} from 'utils/api';

const ACTION_ROOT = 'program-growers';

/* ACTIONS */
const SET_GROWERS              = `${ACTION_ROOT}/SET_GROWERS`;
const CLEAR_GROWERS            = `${ACTION_ROOT}/CLEAR_GROWERS`;
const SET_GROWER_ENGAGEMENT    = `${ACTION_ROOT}/SET_GROWER_ENGAGEMENT`;
const CLEAR_GROWER_ENGAGEMENT  = `${ACTION_ROOT}/CLEAR_GROWER_ENGAGEMENT`;
const SET_BADGE_HASH           = `${ACTION_ROOT}/SET_BADGE_HASH`;
const CLEAR_BADGE_HASH         = `${ACTION_ROOT}/CLEAR_BADGE_HASH`;
const SET_GROWER_SUPPLIERS     = `${ACTION_ROOT}/SET_GROWER_SUPPLIERS`;

const initialState = {
	growers: [],
	engagement: [],
	badgeHash: {},
	growerSuppliers: [],
};

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_GROWERS:
			return { ...state, growers: action.payload };
		case CLEAR_GROWERS:
			return { ...state, growers: initialState.growers };
		case SET_GROWER_ENGAGEMENT:
			return { ...state, engagement: action.payload };
		case CLEAR_GROWER_ENGAGEMENT:
			return { ...state, engagement: initialState.engagement };
		case SET_BADGE_HASH:
			return { ...state, badgeHash: action.payload };
		case CLEAR_BADGE_HASH:
			return { ...state, badgeHash: initialState.badgeHash };
		case SET_GROWER_SUPPLIERS:
			return { ...state, growerSuppliers: action.payload };
		default:
			return state;
	}
}

export const getGrowers = ({
	programKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			endpoint: `reports/programs/${programKey}/producerSummary`,
			dispatch,
			action: SET_GROWERS,
		});
	}
}

export function clearGrowers() {
	return {
		type: CLEAR_GROWERS,
	}
}

export const getAllGrowerBadges = () => {
	return async (dispatch) => {
		return await getData({
			dispatch,
			endpoint: "admin/growers/growerBadges",
			action: SET_BADGE_HASH,
		});
	}
}

export function clearAllGrowerBadges() {
	return {
		type: CLEAR_BADGE_HASH,
	}
}

export const getAllGrowerEngagement = ({
	surveyKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await getData({
			endpoint: `reports/surveys/${surveyKey}/growerEngagement`,
			dispatch,
			action: SET_GROWER_ENGAGEMENT,
		});
	}
}

export function clearAllGrowerEngagement() {
	return {
		type: CLEAR_GROWER_ENGAGEMENT,
	}
}


export const getAllGrowerSuppliers = ({
	programKey=throwIfMissing(),
	organizationKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const { data, error } = await getData({
			endpoint: `organizations/${organizationKey}/growers/suppliers`,
		});

		if ( error ) {
			return;
		}

		const programSuppliers = data.filter( (supplier) => supplier.supplierDetail?.programKey === programKey );
		
		dispatch({
			type: SET_GROWER_SUPPLIERS,
			payload: programSuppliers,
		});
	}
}
