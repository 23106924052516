import { throwIfMissing } from 'utils';

import {
	getAndMergeList,
	getAndMergeSingle,
	updateOrCreateTranslationItems,
} from 'utils/translation';

import {
	postData,
	putData,
	deleteData,
} from 'utils/api';

const endpoint = (surveyKey, questionKey) => {
	return `surveys/${surveyKey}/questions/${questionKey}/feedback`
};

const ACTION_ROOT = 'survey-question-feedback';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllQuestionFeedback = ({
	surveyKey=throwIfMissing(),
	questionKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const keys = {
			surveyKey,
		};

		return await getAndMergeList({
			keys,
			endpoint: endpoint(keys.surveyKey, questionKey),
			listKeyType: "questionFeedbackKey",
			dispatch,
			action: SET_LIST,
		});

	}
}

export function clearQuestionFeedbackList() {
	return {
		type: SET_LIST,
		payload: [],
	}
}

export const createQuestionFeedback = ({
	surveyKey=throwIfMissing(),
	questionKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const response = await postData({
			data: restData,
			endpoint: endpoint(surveyKey, questionKey),
		});

		if ( response.error ) {
			return response;
		}

		const questionFeedbackKey = response.data.key;

		const keys = {
			surveyKey,
			questionFeedbackKey,
		};

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveQuestionFeedback({ surveyKey, questionKey, questionFeedbackKey })(dispatch);

		return response;
	}
}

export function setActiveQuestionFeedbackEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveQuestionFeedback() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getActiveQuestionFeedback = ({
	surveyKey=throwIfMissing(),
	questionKey=throwIfMissing(),
	questionFeedbackKey=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			surveyKey,
			questionFeedbackKey,
		};

		return await getAndMergeSingle({
			keys,
			dispatch,
			endpoint: `${endpoint(keys.surveyKey, questionKey)}/${keys.questionFeedbackKey}`,
			action: SET_ACTIVE,
		});

	}
}

export const updateQuestionFeedback = ({
	surveyKey=throwIfMissing(),
	questionKey=throwIfMissing(),
	questionFeedbackKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const keys = {
			surveyKey,
			questionFeedbackKey,
		};

		const response = await putData({
			data: restData,
			endpoint: `${endpoint(surveyKey, questionKey)}/${questionFeedbackKey}`,
		});

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveQuestionFeedback({ surveyKey, questionKey, questionFeedbackKey })(dispatch);

		return response;
	}
}

export const deleteQuestionFeedback = ({
	surveyKey=throwIfMissing(),
	questionKey=throwIfMissing(),
	questionFeedbackKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: `${endpoint(surveyKey, questionKey)}/${questionFeedbackKey}`,
			action: CLEAR_ACTIVE,
		});
	}
}
