import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";

import ReadControl from '../ReadControl';

export function ReadView( props={} ) {
	const {
		input,
	} = props;

	if ( ! input.value ) {
		return <ReadControl />;
	}

	return (
		<ReadControl>
			<ReactMarkdown linkTarget="_blank" children={ input.value } />
		</ReadControl>
	);
}

ReadView.propTypes = {
	/** input.value used to display value, (usually from ReduxForm.field) */
	input: PropTypes.object.isRequired,

};

export default ReadView;
