import React from 'react';
import PropTypes from 'prop-types';

export function ReadControl( props={} ) {
	return (
		<label className="form-control-plaintext read-control">
			<Contents {...props} />
		</label>
	);
}

function Contents( props={} ) {
	const {
		children,
		muted,
	} = props;

	if ( ! children ) {
		return null;
	}

	if ( ! muted ) {
		return children;
	}

	return <span className="text-muted">{ children }</span>;
}

Contents.propTypes = {
	/** show children contents wrapped in text-muted class */
	muted: PropTypes.bool,
	children: PropTypes.node,
};

ReadControl.propTypes = {
	...Contents.propTypes,
};

export default ReadControl;
