/* eslint no-console: 0 */

import axios from 'axios';
import { reset } from 'redux-form';
import toastr from 'toastr';

import { REACT_APP_API_URL } from 'data/runtimeEnvVars';
import { throwIfMissing } from 'utils';
import { getSessionToken } from 'utils/localStorage';

const BASE_URL = `${REACT_APP_API_URL}`;
const api = axios.create({
	baseURL: BASE_URL,
});

export const UNKNOWN_ERROR_MSG = 'An unexpected error has occurred.';

export function getAuthorizationHeader( sessionToken ) {
	var token = sessionToken + ':';
	var hashed;
	if (window.btoa) {
		hashed = window.btoa(token);
	}
	return "Basic " + hashed;
}

export function getStandardHeaders() {
	return {
		Authorization: getAuthorizationHeader(getSessionToken()),
	};
}

export function showSuccessMessage( msg ) {
	if( msg ) {
		toastr.success(null, msg);
	}
}

export function showErrorMessage( msg ) {
	if( msg ) {
		toastr.error(msg, "Error");
	}
}

export function showInfoMessage( msg ) {
	if( msg ) {
		toastr.info(null, msg);
	}
}

export function getErrorMessage( error ) {
	const NO_INTERNET_MSG = 'Please check your internet connection.';
	const NO_RESPONSE_MSG = 'The server did not respond.';

	let errorMsg = UNKNOWN_ERROR_MSG;

	if( navigator && navigator.onLine === false ) {
		errorMsg = NO_INTERNET_MSG;
	}
	else if( error ) {
		if( ! error.response ) {
			errorMsg = NO_RESPONSE_MSG;
		}
		else {
			if( error.response.data && error.response.data.message ){
				errorMsg = error.response.data.message;
			}
		}
	}

	return errorMsg;
}

export function formatErrorResponse(error) {
  return {
		success: false,
		error: error,
		message: getErrorMessage( error ),
		status: (error.response) ? error.response.status : null,
  }
}

export const handleDataDispatch = ({
	dispatch,
	action,
	response,
}) => {
	if ( dispatch && action && response ) {
		dispatch({
			type: action,
			payload: response.data,
		});
	}
}

export const handleResetForm = ({ formName, dispatch }) => {
	if( formName && dispatch ) {
		dispatch( reset(formName) );
	}
}

export const handleError = (error) => {
	const errorResponse = formatErrorResponse(error);
	console.error( errorResponse.message );
	showErrorMessage( errorResponse.message )	;

	return errorResponse;
}

export const getData = async ({
  endpoint = throwIfMissing(),
  headers = getStandardHeaders(),
	baseURL = BASE_URL,
	dispatch,
	action,
	onError = handleError,
}) => {

	try {
		const response = await api.get(
			endpoint,
			{ headers, baseURL }
		);

		handleDataDispatch({ dispatch, action, response });
		return response;
	} catch(err) {
		return onError(err);
	}

}

export const postData = async ({
  endpoint = throwIfMissing(),
  data = throwIfMissing(),
  headers = getStandardHeaders(),
	baseURL = BASE_URL,
	dispatch,
	action,
	onError = handleError,
	formName,
	successMsg,
}) => {

	try {
		const response = await api.post(
			endpoint,
			data,
			{ headers, baseURL }
		);

		handleDataDispatch({ dispatch, action, response });
		handleResetForm({ formName, dispatch });
		showSuccessMessage(successMsg);
		return response;
	} catch(err) {
		return onError(err);
	}

}

export const putData = async ({
  endpoint = throwIfMissing(),
  data = throwIfMissing(),
  headers = getStandardHeaders(),
	baseURL = BASE_URL,
	dispatch,
	action,
	onError = handleError,
	formName,
	successMsg,
	transformRequest,
}) => {

	try {
		const response = await api.put(
			endpoint,
			data,
			{ headers, baseURL, transformRequest }
		);

		handleDataDispatch({ dispatch, action, response });
		handleResetForm({ formName, dispatch });
		showSuccessMessage(successMsg);
		return response;
	} catch(err) {
		return onError(err);
	}

}

export const deleteData = async ({
  endpoint = throwIfMissing(),
  headers = getStandardHeaders(),
	baseURL = BASE_URL,
	dispatch,
	action,
	onError = handleError,
	formName,
	successMsg,
}) => {

	try {
		const response = await api.delete(
			endpoint,
			{ headers, baseURL }
		);

		handleDataDispatch({ dispatch, action, response });
		handleResetForm({ formName, dispatch });
		showSuccessMessage(successMsg);
		return response;
	} catch(err) {
		return onError(err);
	}

}

export const putString = async (props) => {
	const transformRequest = [
		(data, headers) => {
			return data;
		},
	];

	return await putData({
		...props,
		transformRequest,
	});
}
