import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Control from "./Control";
import ReadView from "./ReadView";
import FormGroupLayout from "./FormGroupLayout";

export { FormGroupLayout };
export { Control };
export { ReadView };

/** returns ReduxForm.Field with defaults */
export function FormControlMarkdownWysiwyg( props={} ) {
	return (
		<Field { ...props }/>
	);
}

FormControlMarkdownWysiwyg.propTypes = {
	/** Pass through model to value (ReduxForm.Field) */
	name: PropTypes.string.isRequired,  // Pass through model to value (field in redux-form 5)

	/** component to render, defaults to FormGroupLayout */
	component: PropTypes.func,
};

FormControlMarkdownWysiwyg.defaultProps = {
	component: FormGroupLayout,
};

export default FormControlMarkdownWysiwyg;
