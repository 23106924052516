import { throwIfMissing } from 'utils';

import {
	getAndMergeList,
	getAndMergeSingle,
	updateOrCreateTranslationItems,
} from 'utils/translation';

import {
	postData,
	// putData,
	deleteData,
} from 'utils/api';

const endpoint = (surveyKey) => {
	return `surveys/${surveyKey}/surveyTags`
};

const ACTION_ROOT = 'survey-tags';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllTags = ({
	surveyKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const keys = {
			surveyKey,
		};

		return await getAndMergeList({
			keys,
			endpoint: endpoint(keys.surveyKey),
			listKeyType: "tagKey",
			dispatch,
			action: SET_LIST,
		});

	}
}

export function clearTagList() {
	return {
		type: SET_LIST,
		payload: [],
	}
}

export const createTag = ({
	surveyKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const response = await postData({
			data: restData,
			endpoint: endpoint(surveyKey),
		});

		if ( response.error ) {
			return response;
		}

		const tagKey = response.data.key;

		const keys = {
			surveyKey,
			tagKey,
		};

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveTag({ surveyKey, tagKey })(dispatch);

		return response;
	}
}

export function setActiveTagEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveTag() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getActiveTag = ({
	surveyKey=throwIfMissing(),
	tagKey=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			surveyKey,
			tagKey,
		};

		return await getAndMergeSingle({
			keys,
			dispatch,
			endpoint: `${endpoint(keys.surveyKey)}/${keys.tagKey}`,
			action: SET_ACTIVE,
		});

	}
}

export const updateTag = ({
	surveyKey=throwIfMissing(),
	tagKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			// ...restData
		} = data;

		const keys = {
			surveyKey,
			tagKey,
		};

		// CURRENTLY NO PROPERTIES AVAILABEL FOR UPDATE (position controlled by "reorder" and labels are translation_items)
		// const response = await putData({
		// 	data: restData,
		// 	endpoint: `${endpoint(surveyKey)}/${tagKey}`,
		// });

		dispatch(setActiveTagEditState(false));

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveTag({ surveyKey, tagKey })(dispatch);
		//
		// return response;
	}
}

export const deleteTag = ({
	surveyKey=throwIfMissing(),
	tagKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: `${endpoint(surveyKey)}/${tagKey}`,
			action: CLEAR_ACTIVE,
		});
	}
}

export const reorderTags = ({
	surveyKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const response = await postData({
			data,
			endpoint: `${endpoint(surveyKey)}/reorder`,
		});

		await getAllTags({ surveyKey })(dispatch);

		return response;
	}
}
