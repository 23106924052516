import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '../FormGroup';
import Control from './Control';
import ReadView from './ReadView';

/** Renders form group with control, read-control, and control label, passing all props to each */
export const FormGroupLayout = (props) => {
	const {
		editState,
	} = props;

	return (
		<FormGroup {...props}>
			{ editState &&
				<Control {...props} />
			}
			{ ! editState &&
				<ReadView {...props} />
			}
		</FormGroup>
	);
}

FormGroupLayout.propTypes = {
	...FormGroup.propTypes,
	...Control.propTypes,
	...ReadView.propTypes,

	/* prop setting to show read or edit view */
	editState: PropTypes.bool,
};

export default FormGroupLayout;
