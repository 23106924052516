import React from 'react';

const SandboxHeader = (props) => {
	return (
		<div style={ styles.wrapper }>
			<span style={ styles.title }>Sandbox Environment</span>
			{" "}-{" "}
			demo and testing use only
		</div>
	);
}

const styles = {
	wrapper: {
		backgroundColor: "#AD061B",
		color: "#fff",
		width: "100%",
		padding: "0.5rem .9375rem",
		lineHeight: "1.5rem",
		fontSize: "1rem",
	},
	title: {
		fontWeight: "bold",
		textTransform: "uppercase",
	}
}
export default SandboxHeader;
