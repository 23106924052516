import { throwIfMissing } from 'utils';

import {
	getAndMergeList,
	getAndMergeSingle,
	updateOrCreateTranslationItems,
	buildName,
	getTranslationItemHash,
} from 'utils/translation';

import {
	postData,
	putData,
	deleteData,
} from 'utils/api';

import {
	getTranslationItemsByNamePrefix,
} from './translation-items';

const endpoint = (surveyKey) => {
	return `surveys/${surveyKey}/answers`
};

const ACTION_ROOT = 'survey-answers';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export const getAllAnswers = ({
	surveyKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const keys = {
			surveyKey,
		};

		return await getAndMergeList({
			keys,
			endpoint: endpoint(keys.surveyKey),
			listKeyType: "answerKey",
			dispatch,
			action: SET_LIST,
		});

	}
}

export function clearAnswerList() {
	return {
		type: SET_LIST,
		payload: [],
	}
}

export const createAnswer = ({
	surveyKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const response = await postData({
			data: restData,
			endpoint: endpoint(surveyKey),
		});

		if ( response.error ) {
			return response;
		}

		const answerKey = response.data.key;

		const keys = {
			surveyKey,
			answerKey,
		};

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveAnswer({ surveyKey, answerKey })(dispatch);

		return response;
	}
}

export function setActiveAnswerEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveAnswer() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export const getActiveAnswer = ({
	surveyKey=throwIfMissing(),
	answerKey=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			surveyKey,
			answerKey,
		};

		return await getAndMergeSingle({
			keys,
			dispatch,
			endpoint: `${endpoint(keys.surveyKey)}/${keys.answerKey}`,
			action: SET_ACTIVE,
		});

	}
}

export const updateAnswer = ({
	surveyKey=throwIfMissing(),
	answerKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const {
			translationItems,
			...restData
		} = data;

		const keys = {
			surveyKey,
			answerKey,
		};

		const response = await putData({
			data: restData,
			endpoint: `${endpoint(surveyKey)}/${answerKey}`,
		});

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getActiveAnswer({ surveyKey, answerKey })(dispatch);

		return response;
	}
}

export const deleteAnswer = ({
	surveyKey=throwIfMissing(),
	answerKey=throwIfMissing(),
}) => {
	return async (dispatch) => {
		return await deleteData({
			dispatch,
			endpoint: `${endpoint(surveyKey)}/${answerKey}`,
			action: CLEAR_ACTIVE,
		});
	}
}

export const reorderAnswers = ({
	surveyKey=throwIfMissing(),
	data=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const response = await postData({
			data,
			endpoint: `${endpoint(surveyKey)}/reorder`,
		});

		await getAllAnswers({ surveyKey })(dispatch);

		return response;
	}
}

export const getSpecialAnswerTranslations = ({
	surveyKey=throwIfMissing(),
	answerValue=throwIfMissing(),
}) => {
	return async ( dispatch ) => {
		const keys = {
			surveyKey,
			answerKey: answerValue,
		};

		const translationItemsPrefix = buildName(keys);
		const { data } = await getTranslationItemsByNamePrefix(translationItemsPrefix);

		const translationItems = getTranslationItemHash(data, translationItemsPrefix);

		const payload = {
			surveyKey,
			translationItems,
			value: answerValue,
		}

		dispatch({
			payload,
			type: SET_ACTIVE,
		});
	}
}

export const updateSpecialAnswerTranslations = ({
	surveyKey=throwIfMissing(),
	answerValue=throwIfMissing(),
	translationItems=throwIfMissing(),
}) => {
	return async (dispatch) => {
		const keys = {
			surveyKey,
			answerKey: answerValue,
		};

		await updateOrCreateTranslationItems({
			keys,
			translationItems,
		});

		await getSpecialAnswerTranslations({ surveyKey, answerValue })(dispatch);
	}
}
